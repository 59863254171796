import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { PROPERTIES_FETCH_STARTED, PROPERTIES_FETCH_SUCCEEDED, PROPERTIES_FETCH_FAILED, PROPERTIES_OPTIMISTICALLY_UPDATED } from './propertiesActionTypes';
export const fetchPropertiesAction = (objectTypeId, __hasGate) => dispatch => {
  dispatch({
    type: PROPERTIES_FETCH_STARTED,
    payload: {
      objectTypeId
    }
  });
  return getFrameworkDataSchemasClient().then(client => client.properties.get({
    frameworkTypeIdentifier: objectTypeId,
    query: {
      showHighlySensitiveProperties: true
    }
  })).then(properties => {
    dispatch({
      type: PROPERTIES_FETCH_SUCCEEDED,
      payload: {
        properties,
        objectTypeId
      }
    });
  }).catch(err => {
    dispatch({
      type: PROPERTIES_FETCH_FAILED,
      payload: {
        objectTypeId
      }
    });
    throw err;
  });
};
export const propertiesOptimisticallyUpdatedAction = (objectTypeId, properties) => ({
  type: PROPERTIES_OPTIMISTICALLY_UPDATED,
  payload: {
    objectTypeId,
    properties
  }
});